import useIsVisible from '@hooks/useIsVisible';
import React, { forwardRef, useState } from 'react';
import type { Gallery } from 'types/reviews';
import mergeRefs from '../../../../../../utils/mergeRefs';
import { ReviewsImageWrapper } from '../../common/reviews-image-wrapper';
import { useCalculateGalleryItemHeight } from '../../hooks/use-calculate-gallery-item-height';
import {
	calculateIsVisible,
	useIsWithinRange,
} from '../../hooks/use-is-within-range';
import Styles from './styles.scss';

const width = 450;

export interface GalleryViewProps {
	currentRange: { maxRange: number; minRange: number };
	index: number;
	setActiveImageIndex: (index: number) => void;
	galleryItem: Gallery;
	onClick: (index: number) => void;
}

const GalleryView = forwardRef<HTMLDivElement, GalleryViewProps>(
	(props, ref) => {
		const { currentRange, index, setActiveImageIndex, galleryItem, onClick } =
			props;
		const [isVisible, setIsVisible] = useState(
			calculateIsVisible({ index, currentRange }),
		);
		const [localRef] = useIsVisible({
			callback: () => setActiveImageIndex(index),
			timeoutMs: 200,
		});
		const mediaRef = mergeRefs(ref, localRef);
		const height = useCalculateGalleryItemHeight(galleryItem, width);

		useIsWithinRange({ isVisible, index, currentRange, setIsVisible });

		return (
			<div
				ref={mediaRef}
				className={Styles.mediaItemContainer}
				onClick={() => onClick(index)}
				onKeyPress={() => onClick(index)}
				role="button"
				tabIndex={0}
			>
				<ReviewsImageWrapper
					item={galleryItem}
					width={width}
					height={height}
					optionalCssClass={Styles.landscapeMedia}
				/>
			</div>
		);
	},
);

export { GalleryView };
