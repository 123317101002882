import type { Review } from '@typings/reviews';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React from 'react';
import { useAbbrName } from '../../../../../../pages/Storefront/containers/reviews/components/review/hooks/useAbbrName';
import { useReviewDate } from '../../../../../../pages/Storefront/containers/reviews/components/review/hooks/useReviewDate';
import ReviewStars from '../../../../../../pages/Storefront/containers/vendor_info/components/review_stars';
import { useGalleryReview } from '../../hooks/use-gallery-review';
import Styles from './styles.scss';
import { useIsOverflow } from './useIsOverflow';

interface ReviewContentProps {
	review: Review;
	handleClick?: () => void;
}

interface ContentProps {
	handleClick?: () => void;
}

const ReviewContent = ({ review, handleClick }: ReviewContentProps) => {
	const {
		content,
		createdDate,
		rating,
		reviewer: { firstName, lastName },
	} = review;
	const ref = React.useRef<HTMLDivElement | null>(null);
	const isOverflow = useIsOverflow(ref, content);
	const [formattedCreatedDate] = useReviewDate(createdDate);
	const abbrName = useAbbrName(firstName, lastName);
	const ratingWithFixedDecimal = Number(rating).toFixed(1);

	return (
		<div data-testid="review-content">
			<div className={Styles.authorDateContainer}>
				<Body1 bold>{abbrName}</Body1>
				<span className={Styles.reviewDate}>
					Reviewed On {formattedCreatedDate}
				</span>
			</div>
			<div className={Styles.reviewStars}>
				<ReviewStars overallRating={rating} size="md" />
				<span className={Styles.reviewStarCount}>{ratingWithFixedDecimal}</span>
			</div>
			<div>
				<p ref={ref} className={Styles.reviewContentText}>
					{content}
					<span
						className={classNames(Styles.readMore, {
							[Styles.hidden]: !isOverflow,
						})}
						data-testid="read-more"
					>
						...
						<button
							type="button"
							className={Styles.readMoreButton}
							onClick={handleClick}
						>
							Read More
						</button>
					</span>
				</p>
			</div>
		</div>
	);
};

const Content = ({ handleClick }: ContentProps) => {
	const review = useGalleryReview();
	if (!review) return null;
	return <ReviewContent review={review} handleClick={handleClick} />;
};

export { Content };
