import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { useAppSelector } from '@redux/hooks';
import { IconButton } from '@xo-union/tk-component-icons';
import { Body1, Caption, Subtle } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { type MouseEvent, type FC, useMemo } from 'react';
import type { Decorated } from 'types/vendor';
import InlineRfqModal from '../../../../../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../../../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../../../../../pages/Storefront/containers/cta_box/RfqButton';
import FavoritesButton from '../../../../../../pages/Storefront/containers/vendor_info/components/favorites_button';
import {
	closeReviewsLightboxAtom,
	reviewslightboxSecondaryTextAtom,
} from '../../atoms';
import Styles from './styles.scss';

interface CloseButtonProps {
	onClick?: (e?: MouseEvent) => void;
}

const CloseButton: FC<CloseButtonProps> = (props) => {
	const { onClick } = props;

	return (
		<IconButton<HTMLButtonElement>
			name="close"
			size="md"
			onClick={onClick}
			aria-label="close"
		/>
	);
};

export const Header = () => {
	const filled = useAppSelector((state) => state.favorites.filled);
	const membership = useAppSelector((state) => state.membership);
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const closeReviewsLightbox = useSetAtom(closeReviewsLightboxAtom);
	const secondaryText = useAtomValue(reviewslightboxSecondaryTextAtom);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();

	const formContext = useInlineRfqForm({
		initiator: 'review gallery',
	});

	const onClick = () => {
		closeReviewsLightbox();
		trackUgcStorefrontAnalytics(
			AnalyticsConstants.DISMISS,
			AnalyticsConstants.REVIEW_GALLERY,
		);
	};

	const headerText = useMemo(() => {
		return `${vendor.name} Review Photos`;
	}, [vendor.name]);

	return (
		<>
			<InlineRfqModal context={formContext} />
			<div className={Styles.header}>
				<div className={Styles.headerLeft}>
					<Body1 bold>{headerText}</Body1>
					{secondaryText?.length ? (
						<div className={Styles.headerSecondaryText}>
							{secondaryText?.map((text, index) => {
								const key = `secondary_text_${index}`;
								return (
									<Subtle key={key}>
										<Caption
											className={classNames(Styles.bullet, {
												[Styles.hidden]: index === 0,
											})}
											aria-hidden="true"
										>
											•
										</Caption>
										<Caption>{text}</Caption>
									</Subtle>
								);
							})}
						</div>
					) : null}
				</div>
				<div className={Styles.headerRight}>
					<div className={Styles.headerButtons}>
						<span
							className={classNames({
								[Styles.favButton]: true,
								[Styles.emptyHeart]: !filled,
							})}
						>
							<FavoritesButton
								expanded
								membership={membership}
								vendor={vendor}
								sourceContent="review gallery"
							/>
						</span>
						<RFQButton size="md" handleCTAClick={formContext.openModal} />
					</div>
					<CloseButton onClick={onClick} />
				</div>
			</div>
		</>
	);
};
