import type { Review } from '@typings/reviews';
import { IconButton } from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';
import { useAbbrName } from '../../../../../../pages/Storefront/containers/reviews/components/review/hooks/useAbbrName';
import { useReviewDate } from '../../../../../../pages/Storefront/containers/reviews/components/review/hooks/useReviewDate';
import ReviewStars from '../../../../../../pages/Storefront/containers/vendor_info/components/review_stars';
import { useGalleryReview } from '../../hooks/use-gallery-review';
import Styles from './styles.scss';

interface ReviewContentProps {
	review: Review;
	handleClick?: () => void;
}

interface ExpandedContentProps {
	handleClick?: () => void;
}

const ReviewContent = ({ review, handleClick }: ReviewContentProps) => {
	const {
		content,
		createdDate,
		rating,
		reviewer: { firstName, lastName },
	} = review;
	const [formattedCreatedDate] = useReviewDate(createdDate);
	const abbrName = useAbbrName(firstName, lastName);
	const ratingWithFixedDecimal = Number(rating).toFixed(1);

	return (
		<div data-testid="expanded-review-content">
			<div className={Styles.expanded}>
				<div className={Styles.authorDateContainer}>
					<Body1 bold>{abbrName}</Body1>
					<span className={Styles.reviewDate}>
						Reviewed On {formattedCreatedDate}
					</span>
				</div>
				<IconButton<HTMLButtonElement>
					name="close"
					size="sm"
					onClick={handleClick}
					aria-label="close"
				/>
			</div>
			<div className={Styles.reviewStars}>
				<ReviewStars overallRating={rating} size="md" />
				<span className={Styles.reviewStarCount}>{ratingWithFixedDecimal}</span>
			</div>
			<p>{content}</p>
		</div>
	);
};

const ExpandedContent = ({ handleClick }: ExpandedContentProps) => {
	const review = useGalleryReview();
	if (!review) return null;
	return <ReviewContent review={review} handleClick={handleClick} />;
};

export { ExpandedContent };
