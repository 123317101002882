import { useScreenDetails } from '@hooks/useScreenDetails';
import React, { type FC } from 'react';
import type { Review } from 'types/reviews';
import { DetailsFilmstrip } from '../details-filmstrip';
import { ReviewContent } from '../review-content';
import Styles from './styles.scss';

export interface ReviewContentWrapperProps {
	review: Review | undefined;
}

const ReviewContentWrapper: FC<ReviewContentWrapperProps> = (props) => {
	const { review } = props;
	const [isLandscape] = useScreenDetails();
	const containerStyles = isLandscape
		? Styles.containerLandscape
		: Styles.container;

	return (
		<div className={containerStyles} data-testid="review-content-wrapper-test">
			<DetailsFilmstrip review={review} />
			<ReviewContent review={review} />
		</div>
	);
};

export { ReviewContentWrapper };
