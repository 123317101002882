import { Highlight } from '@components/highlight/highlight';
import { DisplayButton } from '@xo-union/tk-ui-links';
import React from 'react';
import InlineRfqModal from '../../../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { generateDefaultField } from '../../../../pages/Storefront/containers/InlineRFQ/utils';
import Styles from './location-highlights.scss';

type LocationHighlightsProps = {
	serviceArea?: string;
	travelsUpTo?: number | null;
};

export const LocationHighlights: React.FC<LocationHighlightsProps> = ({
	serviceArea,
	travelsUpTo,
}) => {
	const localFormContext = useInlineRfqForm({
		freeTextLabel: '',
		headerText: 'Get a personalized quote',
		textAreaInitialValueOverride: generateDefaultField('textarea'),
		initiator: 'Travel CTA',
	});

	return (
		<div
			className={`${Styles.container} ${
				serviceArea && travelsUpTo ? Styles.double : Styles.single
			}`}
		>
			<InlineRfqModal context={localFormContext} />
			{serviceArea && (
				<Highlight
					iconName="address"
					classes={{
						container: Styles.highlight,
						title: Styles.highlightTitle,
						text: Styles.highlightText,
					}}
					highlightTitle={`${serviceArea}`}
					highlightText={'Service area'}
				/>
			)}
			{travelsUpTo && (
				<Highlight
					iconName="category-trv"
					classes={{
						container: Styles.highlight,
						title: Styles.highlightTitle,
						text: Styles.highlightText,
					}}
					highlightTitle={`Travels up to ${travelsUpTo} miles`}
					highlightText={
						<div>
							<DisplayButton
								onClick={localFormContext.openModal}
								className={Styles.highlightButton}
								type="button"
								size="sm"
								color="tertiary"
								data-testid="location-highlights-personalized-quote"
							>
								Get a personalized quote
							</DisplayButton>
						</div>
					}
				/>
			)}
		</div>
	);
};
