import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import {
	closeReviewsLightboxAtom,
	reviewsLightboxModalTypeAtom,
} from '../../../atoms';

const useCloseReviewsLightbox = () => {
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();
	const closeReviewsLightBox = useSetAtom(closeReviewsLightboxAtom);
	const modalType = useAtomValue(reviewsLightboxModalTypeAtom);

	const handleClick = useCallback(() => {
		if (modalType === 'overview') {
			trackUgcStorefrontAnalytics(
				AnalyticsConstants.DISMISS,
				AnalyticsConstants.REVIEW_OVERVIEW,
			);
			closeReviewsLightBox();
		}
		if (modalType === 'details') {
			trackUgcStorefrontAnalytics(
				AnalyticsConstants.DISMISS,
				AnalyticsConstants.REVIEW_GALLERY,
			);
			closeReviewsLightBox();
		}
	}, [modalType, trackUgcStorefrontAnalytics]);

	return handleClick;
};

export { useCloseReviewsLightbox };
