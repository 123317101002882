import { PricingDetails } from '@components/pricing/pricing-details/PricingDetails';
import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { H3 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC, memo } from 'react';
import type { NavItemProps } from '../../pages/Storefront/containers/nav/types';
import Styles from './styles.scss';

export const VenuePricing: FC<NavItemProps> = memo(() => {
	const impressionRef = useTrackStorefrontImpression(
		'Venue Pricing Section & FAQs',
	);

	return (
		<div className={classNames([Styles.venuePricingRow])}>
			<H3 ref={impressionRef}>Pricing details</H3>
			<PricingDetails />
		</div>
	);
});
