import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { reviewsLightboxGalleryAtom, reviewsLightboxIndexAtom } from '../atoms';

const useUpdateIndexBySourceId = () => {
	const setReviewsLightboxIndex = useSetAtom(reviewsLightboxIndexAtom);
	const reviewsLightboxGallery = useAtomValue(reviewsLightboxGalleryAtom);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();

	return useCallback(
		(sourceId: string) => {
			const index = reviewsLightboxGallery.findIndex(
				(g) => g.sourceId === sourceId,
			);
			setReviewsLightboxIndex(index);
			trackUgcStorefrontAnalytics(
				AnalyticsConstants.NAV_TO_PHOTO,
				AnalyticsConstants.REVIEW_GALLERY,
			);
		},
		[
			reviewsLightboxGallery,
			setReviewsLightboxIndex,
			trackUgcStorefrontAnalytics,
		],
	);
};

export { useUpdateIndexBySourceId };
