import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { type FC, useCallback, useMemo, useRef } from 'react';
import {
	openReviewsLightboxAtom,
	reviewsLightboxGalleryAtom,
} from '../../atoms';
import { GalleryView } from '../gallery-view';
import Styles from './styles.scss';

const PRELOAD_RANGE = 6;

export interface OverviewLightboxProps {
	activeImageIndex: number;
	setActiveImageIndex: (index: number) => void;
}

const OverviewLightbox: FC<OverviewLightboxProps> = (props) => {
	const { activeImageIndex, setActiveImageIndex } = props;
	const openReviewsLightbox = useSetAtom(openReviewsLightboxAtom);
	const galleryItems = useAtomValue(reviewsLightboxGalleryAtom);
	const galleryItemRefs = useRef<Array<HTMLDivElement | null>>([]);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();

	const currentRange = useMemo(
		() => ({
			maxRange: activeImageIndex + PRELOAD_RANGE,
			minRange: activeImageIndex - PRELOAD_RANGE,
		}),
		[activeImageIndex],
	);

	const handleClick = useCallback(
		(index) => {
			openReviewsLightbox({
				gallery: galleryItems,
				index,
				modalType: 'details',
			});
			trackUgcStorefrontAnalytics(
				AnalyticsConstants.PHOTO_VIEWED,
				AnalyticsConstants.REVIEW_OVERVIEW,
			);
		},
		[openReviewsLightbox, galleryItems, trackUgcStorefrontAnalytics],
	);

	return (
		<div className={Styles.content}>
			{galleryItems.map((media, i) => {
				return (
					<GalleryView
						currentRange={currentRange}
						key={media.id}
						index={i}
						ref={(el) => {
							galleryItemRefs.current[i] = el;
						}}
						setActiveImageIndex={setActiveImageIndex}
						galleryItem={media}
						onClick={() => handleClick(i)}
					/>
				);
			})}
		</div>
	);
};

export { OverviewLightbox };
