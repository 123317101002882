import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { useScreenDetails } from '@hooks/useScreenDetails';
import { Carousel } from '@xo-union/ui-carousel';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback } from 'react';
import useCarouselSize from '../../../../../../pages/Storefront/hooks/useCarouselSize';
import {
	reviewsLightboxGalleryAtom,
	reviewsLightboxIndexAtom,
} from '../../atoms';
import { ReviewsImageWrapper } from '../../common/reviews-image-wrapper';
import {
	useFetchGalleryReviews,
	useGalleryReview,
} from '../../hooks/use-gallery-review';
import { MobilePhotoCount } from '../photo-count';
import { ReviewContentWrapper } from '../review-content-wrapper';
import {
	getCarouselStyles,
	getLightboxWrapperStyles,
	getPhotoStyles,
} from '../utils';

const DetailsLightbox = () => {
	const review = useGalleryReview();
	useFetchGalleryReviews();
	const galleryItems = useAtomValue(reviewsLightboxGalleryAtom);
	const [reviewsLightboxIndex, setReviewsLightboxIndex] = useAtom(
		reviewsLightboxIndexAtom,
	);
	const [isLandscape, width, height] = useScreenDetails();
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();

	const onNavigationChange = useCallback(
		(index) => {
			setReviewsLightboxIndex((prevState) => {
				trackUgcStorefrontAnalytics(
					`${
						prevState < index
							? AnalyticsConstants.NAV_RIGHT
							: AnalyticsConstants.NAV_LEFT
					}`,
					AnalyticsConstants.REVIEW_GALLERY,
				);
				return index;
			});
		},
		[trackUgcStorefrontAnalytics],
	);

	const responsive = useCallback(() => ({ slides: 1 }), []);

	return (
		<>
			<Carousel
				classes={getCarouselStyles(isLandscape)}
				gutter={0}
				responsive={responsive}
				navigationIndex={reviewsLightboxIndex}
				onNavigationIndexChange={onNavigationChange}
				{...useCarouselSize()}
			>
				{galleryItems.map((media) => {
					const { image, pill } = getPhotoStyles(isLandscape);
					return (
						<div
							className={getLightboxWrapperStyles(media)}
							key={media.id}
							data-testid="test-details-lightbox-item"
						>
							<MobilePhotoCount
								currentIndex={reviewsLightboxIndex}
								totalNumber={galleryItems.length}
								cssClassOverride={pill}
							/>
							<ReviewsImageWrapper
								item={media}
								height={height}
								width={width}
								optionalCssClass={image}
							/>
						</div>
					);
				})}
			</Carousel>
			{!isLandscape && <ReviewContentWrapper review={review} />}
		</>
	);
};

export { DetailsLightbox };
