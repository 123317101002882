import Icon from '@xo-union/tk-component-icons';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import { reviewsLightboxModalTypeAtom } from '../../../atoms';
import { useCloseReviewsLightbox } from '../hooks/use-close-reviews-lightbox';
import Styles from './header.scss';

export interface HeaderProps {
	vendorName: string;
}

const Header: FC<HeaderProps> = (props) => {
	const { vendorName } = props;
	const modalType = useAtomValue(reviewsLightboxModalTypeAtom);
	const closeModal = useCloseReviewsLightbox();
	const headerStyles =
		modalType === 'details'
			? `${Styles.header} ${Styles.headerForDetails}`
			: Styles.header;

	return (
		<div className={headerStyles}>
			<div className={Styles.titleContainer}>
				<h4 className={Styles.title}>{vendorName}</h4>
			</div>
			<div className={Styles.closeBtnContainer}>
				<button
					aria-label="Close modal"
					name="close-btn-overview"
					className={Styles.closeBtn}
					onClick={closeModal}
					type="button"
				>
					<Icon name="close" size="md" />
				</button>
			</div>
		</div>
	);
};

export { Header };
