import classNames from 'classnames';
import React, { memo } from 'react';
import type { Gallery } from 'types/reviews';
import { ReviewsImageWrapper } from '../../common/reviews-image-wrapper';
import { PhotoCount } from '../photo-count';
import Styles from './styles.scss';

export interface MediaProps {
	galleryItem: Gallery;
	totalCount: number;
	index: number;
	visible: boolean;
}

const getDimensionProps = (galleryItem: Gallery) => {
	if (!('height' in galleryItem) || !('width' in galleryItem)) {
		return {};
	}

	const { height, width } = galleryItem;

	return width >= height
		? { width: 1016, height: 678 }
		: { width: 513, height: 770 };
};

export const MediaContainer = memo(
	({ galleryItem, index, totalCount, visible }: MediaProps) => {
		const dimensionProps = getDimensionProps(galleryItem);
		const hasDimensions = 'height' in galleryItem && 'width' in galleryItem;
		return (
			<>
				<div
					className={classNames(Styles.mediaContainerWrapper, 'bone', {
						[Styles.hidden]: !visible,
						[Styles.mediaUnknownDimensions]: !hasDimensions,
					})}
					data-testid={`media-container-wrapper-${index}`}
				>
					<div className={Styles.mediaContainer}>
						<PhotoCount totalCount={totalCount} index={index} />
						<ReviewsImageWrapper item={galleryItem} {...dimensionProps} />
					</div>
				</div>
			</>
		);
	},
);
