import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import {
	reviewsLightboxGalleryAtom,
	reviewsLightboxIndexAtom,
	reviewsLightboxNavigationChange,
} from '../../atoms';
import { ReviewsImageWrapper } from '../../common/reviews-image-wrapper';
import Styles from './styles.scss';

const small = [240, 215];
const tall = [240, 445];
const wide = [488, 215];

const imageDimensions = [small, tall, small, wide, tall, small, small, wide];

export const Overview = () => {
	const galleryItems = useAtomValue(reviewsLightboxGalleryAtom);
	const setImage = useSetAtom(reviewsLightboxNavigationChange);
	const index = useAtomValue(reviewsLightboxIndexAtom);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();

	const setActiveImage = (idx: number) => {
		setImage({ value: idx });
		trackUgcStorefrontAnalytics(
			AnalyticsConstants.NAV_TO_PHOTO,
			AnalyticsConstants.REVIEW_GALLERY,
		);
	};

	return (
		<div className={classNames(Styles.overview, Styles.overviewGrid)}>
			{galleryItems.map((galleryItem, idx) => {
				const dimensions = imageDimensions[idx % 8];
				const key = `${idx}_overview_${galleryItem.id}`;
				const selected = idx === index;
				return (
					<div
						key={key}
						className={classNames(Styles.imgContainer, {
							[Styles.selected]: selected,
						})}
						onClick={() => setActiveImage(idx)}
						onKeyPress={() => setActiveImage(idx)}
						role="button"
						tabIndex={0}
					>
						<ReviewsImageWrapper
							item={galleryItem}
							height={dimensions[1]}
							width={dimensions[0]}
						/>
					</div>
				);
			})}
		</div>
	);
};
